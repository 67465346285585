const getPeriodNames = {
    day: ' do dia',
    month: 'do mês',
    custom: 'do período',
    week: 'da semana',
};

const defaultPanelFilters = [
    'Todos',
    'Vendas',
    'Devoluções',
    'Cancelamentos',
];

const maisCarrinhoFilters = ['Todos', 'Pendentes', 'Finalizados'];

const availaleBrandsFilter = [
    'Animale',
    'Farm',
    'A.Brand',
    'Fábula',
    'OffPremium',
    'Foxton',
    'CrisBarros',
    'Maria Filó',
];

const channelFilter = [
    {
        value: 'allOrders',
        label: 'Todas',
    },
    {
        value: 'Fisico',
        label: 'Física',
    },
    {
        value: 'Online',
        label: 'Online',
    },
    {
        value: 'Vitrine',
        label: 'Vitrine',
    },
    {
        value: 'Externa',
        label: 'Externa',
    },
    {
        value: 'Box',
        label: 'Box',
    },
];

export {
    getPeriodNames,
    defaultPanelFilters,
    availaleBrandsFilter,
    channelFilter,
    maisCarrinhoFilters,
};
