import React from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// Local component import
import ClientData from './ClientData';
import OrderData from './OrderData';

import * as Style from './style';

function ListItem({ order, multimarca, setCurrentPage }) {
    const history = useHistory();
    const location = useLocation().pathname;

    const getPackageId = () => {
        if (order.idPacote) {
            return `${order.idPacote}`;
        }
        return '';
    };

    const handleClick = () => {
        const isCancelled = order?.status_evento === "CANCELADO" ? "&isCancelled=true" : "";        
        const getMomentQuery = moment
            .utc(order?.creationDate)
            .format('YYYY-MM-DD');

        const omniMomentQuery = moment
            .utc(order?.invoicedDate)
            .format('YYYY-MM-DD');

        const packageId = getPackageId();

        if (location === '/storeOmni') {
            history.push({
                pathname: `/detailOrder/${order?.orderId}/${order?.salesCode}/${omniMomentQuery}/${packageId}${isCancelled}`,
                state: { order },
            });
            return;
        }
        if (location === '/maisCarrinho') {
            setCurrentPage(order)
            return;
        }

        history.push(
            `/detailOrder/${order?.orderId}/${order?.vendedor}/${getMomentQuery}/${packageId}${isCancelled}`
        );
    };

    return (
        <Style.ListItemContainer onClick={() => handleClick()}>
            <Style.ListItemContainerGrid>
                <ClientData order={order} multimarca={multimarca} />
                <OrderData order={order} />
            </Style.ListItemContainerGrid>
        </Style.ListItemContainer>
    );
}

export default ListItem;
