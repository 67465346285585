import { formatPrice } from './format';

const getListByFilter = (list, filter) => {
    let filteredList = list;
    switch (filter) {
        case 'Vendas':
            filteredList = list
                .filter(
                    order =>
                        order.status_evento !== 'CANCELADO' &&
                        order.status !== 'CANCELADO'
                )
                .filter(order => order.channel !== 'DEVOLUCAO');
            break;
        case 'Devoluções':
            filteredList = list.filter(order => order.channel === 'DEVOLUCAO');
            break;
        case 'Cancelamentos':
            filteredList = list.filter(
                order =>
                    order.status_evento === 'CANCELADO' ||
                    order.status === 'CANCELADO'
            );
            break;
        default:
            filteredList = list;
            break;
    }
    return filteredList;
};

const applySearchFitler = (list, search) => {
    const formattedSearch = search.toLowerCase().trim();

    const newFilteredOrders = list.filter(order => {
        const orderId = order?.orderId || '';
        const clientName = order?.clientData?.nome || '';
        const formattedOrderId = orderId.toLowerCase();
        const formattedClientName = clientName.toLowerCase();
        return (
            formattedOrderId.includes(formattedSearch) ||
            formattedClientName.includes(formattedSearch)
        );
    });

    return newFilteredOrders;
};

const getTotalValue = list => {
    const getTotalFilterValue = formatPrice(
        list.reduce((totalValue, order) => totalValue + order?.valueAsNumber, 0)
    );

    return getTotalFilterValue;
};

const applyOrdersFilter = (list, filter) => {
    const newFilteredOrders = list.filter(
        order => order.channel === filter.toUpperCase()
    );

    return newFilteredOrders;
};

const getCartsListByFilter = (carts, filter) => {
    let filteredCartsList = carts;

    switch (filter) {
        case 'Todos':
            filteredCartsList = carts;
            break;
        case 'Pendentes':
            filteredCartsList = carts?.filter(
                cart => cart?.status === 'PENDENTE'
            );
            break;
        case 'Finalizados':
            filteredCartsList = carts?.filter(
                cart => cart?.status === 'FINALIZADO'
            );
            break;
        default:
            filteredCartsList = carts;
            break;
    }

    return filteredCartsList;
};

export {
    getListByFilter,
    applySearchFitler,
    getTotalValue,
    applyOrdersFilter,
    getCartsListByFilter,
};
