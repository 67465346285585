import React, { useState } from 'react';
import {
    Typography,
    DialogContent,
    FormControl,
    RadioGroup,
    SvgIcon,
    Radio,
} from '@mui/material';

// Asset import
import { ReactComponent as FilterAltOutlined } from '~/assets/icons/filter_alt_outlined.svg';
import { ReactComponent as FilterAlt } from '~/assets/icons/filter_alt.svg';

// Util import
import { channelFilter } from '~/util/ordersPanelArrays';

import * as Style from '../styles';
import {
    applyOrdersFilter,
    applySearchFitler,
    getListByFilter,
    getTotalValue,
} from '~/util/orderPanelFunctions';

function OrdersFilter({
    ordersFilter,
    setOrdersFilter,
    setFilteredOrders,
    orders,
    filter,
    searchFilter,
    setTotalFilterValue,
}) {
    const [ordersFilterDialogIsOpen, setOrdersFilterDialogIsOpen] = useState(
        false
    );

    const filterLabel = ordersFilter === 'allOrders' ? 'Filtrar' : ordersFilter;

    const handleClickOnRadioButton = event => {
        const chosenFilter = event.target.value;
        let filteredOrders = orders;

        if (chosenFilter !== 'allOrders') {
            chosenFilter === 'Box' ? 
            filteredOrders = applyOrdersFilter(filteredOrders, 'Somastore') : 
            filteredOrders = applyOrdersFilter(filteredOrders, chosenFilter);
        }

        if (filter && filter !== 'Todos') {
            filteredOrders = getListByFilter(filteredOrders, filter);
        }

        if (searchFilter) {
            filteredOrders = applySearchFitler(filteredOrders, searchFilter);
        }

        const getFilterTotals = getTotalValue(filteredOrders);

        setOrdersFilter(chosenFilter);
        setTotalFilterValue(getFilterTotals);
        setFilteredOrders(filteredOrders);
        setOrdersFilterDialogIsOpen(false);
    };

    return (
        <>
            <Style.DialogContainer
                onClose={() => setOrdersFilterDialogIsOpen(false)}
                open={ordersFilterDialogIsOpen}
            >
                <Style.BrandFilterTitleContainer container>
                    <SvgIcon
                        style={{
                            fill: '#DCC6B7',
                            verticalAlign: 'text-bottom',
                        }}
                        component={FilterAltOutlined}
                    />
                    <Typography display="inline">Exibir Somente</Typography>
                </Style.BrandFilterTitleContainer>
                <DialogContent>
                    <FormControl component="fieldset">
                        <RadioGroup
                            value={ordersFilter}
                            onChange={e => handleClickOnRadioButton(e)}
                        >
                            {channelFilter.map(channel => (
                                <Style.BrandsFormControlLabel
                                    value={channel.value}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#DCC6B7',
                                                '&.Mui-checked': {
                                                    color: '#DCC6B7',
                                                },
                                            }}
                                        />
                                    }
                                    label={channel.label}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </Style.DialogContainer>
            <Style.BrandFilterTextContainer
                item
                container
                primary
                onClick={() => {
                    setOrdersFilterDialogIsOpen(true);
                }}
            >
                <SvgIcon
                    style={{
                        fill: '#DCC6B7',
                        verticalAlign: 'text-bottom',
                    }}
                    component={
                        ordersFilter === 'allOrders'
                            ? FilterAltOutlined
                            : FilterAlt
                    }
                />
                <Style.BrandFilterTextType>
                    {filterLabel}
                </Style.BrandFilterTextType>
            </Style.BrandFilterTextContainer>
        </>
    );
}

export default OrdersFilter;
