import React from 'react';

import UndoIcon from '@mui/icons-material/Undo';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const getIconFromMultimarcaOrder = order => {
    if (order.status_evento === 'CANCELADO' || order.status === 'CANCELADO') {
        return (
            <CancelOutlinedIcon
                style={{
                    color: '#E26257',
                }}
            />
        );
    }
    if (order.channel === 'DEVOLUCAO') {
        return (
            <UndoIcon
                style={{
                    color: '#E26257',
                }}
            />
        );
    }
    return (
        <CheckCircleOutlinedIcon
            style={{
                color: '#1BB55C',
            }}
        />
    );
};

export default getIconFromMultimarcaOrder;
